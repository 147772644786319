import React from 'react';
import { graphql } from 'gatsby';
import List from '../components/list';
import Layout from '../components/layout';
import SEO from '../components/seo';
import BrandLogo from '../components/brandlogo';

const svgStyle = {
    style: {
        width: 150,
        maxHeight: 90
    }
};

const logoMapping = {
    ettan: <BrandLogo.Ettan {...svgStyle} />,
    granit: <BrandLogo.Granit {...svgStyle} />,
    general: <BrandLogo.General {...svgStyle} />,
    'göteborgs rapé': <BrandLogo.Goteborgs {...svgStyle} />,
    kaliber: <BrandLogo.Kaliber {...svgStyle} />,
    kronan: <BrandLogo.Kronan {...svgStyle} />,
    catch: <BrandLogo.Catch {...svgStyle} />,
    grov: <BrandLogo.Grov {...svgStyle} />,
    jakobssons: <BrandLogo.Jakobssons {...svgStyle} />,
    knekt: <BrandLogo.Knekt {...svgStyle} />,
    knox: <BrandLogo.Knox {...svgStyle} />,
    lundgrens: <BrandLogo.Lundgrens {...svgStyle} />,
    ld: <BrandLogo.LD {...svgStyle} />,
    mocca: <BrandLogo.Mocca {...svgStyle} />,
    'röda lacket': <BrandLogo.RodaLacket {...svgStyle} />,
    skruf: <BrandLogo.Skruf {...svgStyle} />,
}

export default ({ data, location: { pathname }, pageContext: { brand }}) => {
    const capitalizedBrand = brand.charAt(0).toUpperCase() + brand.slice(1);
    const className = brand.replace(' ', '-').replace('ö', 'o').replace('é', 'e');

    return (
        <Layout>
            <SEO title={`${capitalizedBrand} | Jämför snuspriser online`} keywords={[brand, 'billigt', 'snus', 'billigprilla.se']} pathname={pathname} />
            <h1 className="text-3xl mt-2 capitalize">
                <span className="sr-only">{brand}</span>
                <span className={`w-full h-32 rounded border brandDisplay flex items-center justify-center ${className}`}>
                    {logoMapping[brand]}
                </span>
            </h1>
            <List data={data.allSnus.nodes} />
        </Layout>
    )
}

export const query = graphql`
    query($brand: String!) {
        allSnus(filter: { brand: { eq: $brand } }) {
            nodes {
                    ...snusFragment
                }
            }
    }
`
