import React, { useReducer, useState } from 'react';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import Icon from './icons';

const reducer = (state, action) => {
    const { value } = action || {};
    switch (action.type) {
        case 'type':
            return {
                ...state,
                type: { ...state.type, [value]: !state.type[value] },
            };
        case 'format':
            return {
                ...state,
                format: { ...state.format, [value]: !state.format[value] },
            };
        default:
            return state;
    }
};

const initialState = {
    type: {
        'all white portion': true,
        'white portion': true,
        'original portion': true,
        lös: true,
    },
    format: {
        mini: true,
        'super slim': true,
        slim: true,
        large: true,
        lös: true
    },
    strength: 0,
};

const DropdownSelect = ({ items, handleChange, title, ignore, isOpen }) => {
    const renderItems = Object.keys(items).filter(k => k !== ignore).map(key => (
        <li key={key} className="hover:bg-gray-200">
            <label className="p-2 capitalize flex items-center cursor-pointer text-sm select-none">
                <input disabled={!isOpen} className="mr-2" type="checkbox" value={key} checked={items[key]} onChange={handleChange} />
                {key}
            </label>
        </li>
    ));
    return (
        <ul className="p-2">
            <li>
                <p className="pl-2">{title}</p>
            </li>
            {renderItems}
        </ul>
    );
};

const List = ({ data }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [state, dispatch] = useReducer(reducer, initialState);
    
    const handleType = e => {
        const { value } = e.target;
        dispatch({ type: 'type', value });
    };
    const handleFormat = e => {
        const { value } = e.target;
        dispatch({ type: 'format', value });
    };
    const d = data
        .filter(s => state.type[s.type])
        .filter(s => state.format[s.format]);

    return (
        <>
            <div className="w-full border bg-white rounded my-3 hover:shadow-md">
                <div
                    className="flex"
                    style={{ maxHeight: isOpen ? 200 : 0, overflow: 'hidden', transition: 'max-height .2s ease' }}
                >
                    <DropdownSelect title="Typ" items={state.type} handleChange={handleType} isOpen={isOpen} />
                    <DropdownSelect title="Format" items={state.format} handleChange={handleFormat} ignore="lös" isOpen={isOpen} />
                </div>
                <button onClick={() => setIsOpen(po => !po)} className={`p-2 text-black flex items-center justify-center w-full focus:outline-none focus:shadow-md ${isOpen && 'border-t'}`}>
                    <span className="mr-2">Filter</span>
                    <Icon.ArrowDown style={{ transform: isOpen && 'rotate(180deg)', transition: 'transform .2s ease' }} className="h-4" />
                </button>
            </div>
            <ul>
                {
                    d.map(snus => (
                            <ListItem key={snus.brandName} {...snus} />
                        )
                    )
                }
            </ul>
        </>
    );
};

const ListItem = (props) => {
    const {
        brandName,
        brand,
        name,
        prices,
        slug,
        type,
        format,
        strength,
        productImage: {
            childImageSharp: {
                fluid
            }
        },
    } = props;

    const [lowestPrice] = prices || [];
    const { price } = lowestPrice || {};

    const strArr = new Array(5).fill(false).fill(true, 0, strength);

    const pillStyle = {
        'all white portion': 'bg-teal-100 border-teal-100 text-teal-900',
        'white portion': 'bg-gray-300 border-gray-300 text-gray-900',
        'original portion': 'bg-gray-700 border-gray-700 text-white',
        lös: 'bg-yellow-900 border-yellow-900 text-white'
    };

    const typeStr = type; // .replace(' portion', ''); //replace portion

    return (
        <li
            className="border-l-2 rounded bg-white mb-3 capitalize hover:border-primary hover:shadow-md"
            style={{ transition: 'all .2s ease' }}
        >
            <Link to={slug} alt={brandName} className="flex p-3 border border-l-0">
                <Img
                    className="block w-16 h-16 mr-3 sm:w-20 sm:h-20 sm:ml-3 sm:mr-6 self-center"
                    fluid={fluid}
                />
                <div
                    className="flex-1"
                >
                    <h2 className="text-sm sm:text-md text-gray-700">{brand}</h2>
                    <h3 className="text-md sm:text-lg font-semibold mb-1">{name}</h3>

                    <div className="text-xs capitalize flex flex-wrap items-start">
                        <span className={`block my-1 mr-1 px-2 py-px border rounded-full ${pillStyle[type]}`}>{typeStr}</span>
                        <span className={`block my-1 mr-1 px-2 py-px rounded-full bg-white border border-gray-700 text-gray-700`}>{format}</span>
                    </div>

                    <div className="text-xs flex flex-wrap justify-between">
                        <div className="normal-case my-1 mr-1 px-2 py-px rounded-full border border-gray-700 text-gray-700 inline-flex items-center">
                            <span className="mr-1">Styrka:</span>
                            {strArr.map((s, i) => (
                                <span
                                    key={i}
                                    className={`w-3 h-3 border rounded-full border-gray-700 ${s ? 'bg-gray-700' : 'bg-white'} ${i !== 4 && 'mr-1'}`}
                                />
                            ))}
                        </div>
                        <span className="normal-case px-2 py-px ml-auto self-center rounded border border-gray-700 bg-gray-700 text-white whitespace-no-wrap">{price} kr</span>
                    </div>
                </div>
                <Icon.ArrowRight className="self-center ml-3" width="16" height="16" />
            </Link>
        </li>
    )
}

export default List;
